import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './style/index.css';
import { Amplify } from 'aws-amplify';
import awsConfig from './awsConfig';
import App from './app/App';
import Home from './page/Home';
import Terms from './page/Terms';
import Privacy from './page/Privacy';
import CreateAccount from './page/CreateAccount';

const root = ReactDOM.createRoot(document.getElementById('root'));

Amplify.configure(awsConfig);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/app" element={<App />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/create-account" element={<CreateAccount />} />
      </Routes>
    </Router>
  </React.StrictMode>
);