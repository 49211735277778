import React, { useState, useEffect } from 'react';
import '../../../style/app/component/interface/Optimize.css';

function Optimize({ 
  resumeText, 
  resumeFile, 
  jobPostText, 
  jobPostLink, 
  setRequestResult,
  setIsProcessing,
  setEvaluationResult,
  setOptimizedResult,
  setJobTitle,
  userToken,
  userId
}) {
  const [isOptimizeEnabled, setIsOptimizeEnabled] = useState(false);
  const apiUrl = process.env.REACT_APP_SERVER_URL;
  const DisabledTitle = "Feature not yet enabled.";
  const [isChecked, setIsChecked] = useState(true);
  const [selectedOption, setSelectedOption] = useState('option1');

  useEffect(() => {
    const hasResumeInput = resumeFile !== null || resumeText.length >= 100;
    const hasJobPostInput = isValidUrl(jobPostLink) || jobPostText.length >= 100;
    setIsOptimizeEnabled(hasResumeInput && hasJobPostInput);
  }, [resumeText, resumeFile, jobPostText, jobPostLink]);

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleEvaluate = async () => {
    setIsOptimizeEnabled(false);
    setIsProcessing(true);
    try {
      const payload = {
        resume_text: resumeFile ? null : resumeText,
        resume_pdf: resumeFile ? resumeFile : null,
        job_url: isValidUrl(jobPostLink) ? jobPostLink : null,
        job_text: isValidUrl(jobPostLink) ? null : jobPostText,
        user_id: userId,
        action: 'evaluate'
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
      
      const responseText = await response.text();
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        throw new Error('Failed to parse response JSON');
      }
  
      if (typeof data.body === 'string') {
        try {
          data = JSON.parse(data.body);
        } catch (parseError) {
          throw new Error('Failed to parse nested JSON in response body');
        }
      }
  
      if ('evaluation' in data) {
        setEvaluationResult(data.evaluation);
      }
      
      setRequestResult(data);
    } catch (error) {
      setEvaluationResult(`Error: ${error.message}. Please try again.`);
    } finally {
      setIsProcessing(false);
    }
  };  

  const handleOptimize = async () => {
    setIsOptimizeEnabled(false);
    setIsProcessing(true);
    try {
      const payload = {
        resume_text: resumeFile ? null : resumeText,
        resume_pdf: resumeFile ? resumeFile : null,
        job_url: isValidUrl(jobPostLink) ? jobPostLink : null,
        job_text: isValidUrl(jobPostLink) ? null : jobPostText,
        user_id: userId,
        action: 'optimize'
      };
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
      
      const responseText = await response.text();
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        throw new Error('Failed to parse response JSON');
      }
  
      if (typeof data.body === 'string') {
        try {
          data = JSON.parse(data.body);
        } catch (parseError) {
          throw new Error('Failed to parse nested JSON in response body');
        }
      }
  
      if ('job_title' in data) {
        setJobTitle(data.job_title);
      }
      if ('evaluation' in data) {
        setEvaluationResult(data.evaluation);
      }
      
      setRequestResult(data);
    } catch (error) {
      setEvaluationResult(`Error: ${error.message}. Please try again.`);
      setOptimizedResult('');
      setJobTitle('');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRegenerate = () => {
    // Placeholder for regenerate functionality
  };
  
  return (
    <div className="optimize-container">
      <div className="optimize-controls">
        <button 
          onClick={handleOptimize} 
          disabled={!isOptimizeEnabled}
          className={`optimize-button ${isOptimizeEnabled ? 'enabled' : ''}`}
          hover-tooltip={`
            ${!isOptimizeEnabled ? 
              "Please provide your resume and a job post to enable optimization" :
              "Click to optimize your resume for the given job post"}
          `}
        >
          <span className="optimize-button-title">Optimize</span>
          <span className="optimize-button-subtitle">Evaluate and update resume</span>
        </button>
        {/* <div className="checkbox-container">
          <input
            type="checkbox"
            id="auto-optimize"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="optimize-checkbox"
            disabled={!isOptimizeEnabled}
            hover-tooltip={DisabledTitle}
          />
          <label htmlFor="auto-optimize">Auto-Optimize</label>
        </div> */}
        <button 
          onClick={handleEvaluate} 
          disabled={!isOptimizeEnabled}
          className={`evaluate-button ${isOptimizeEnabled ? 'enabled' : ''}`}
          hover-tooltip={`
            ${!isOptimizeEnabled ? 
              "Please provide your resume and a job post to enable evaluation" :
              "Click to evaluate your resume for the given job post"}
          `}
        >
          <span className="evaluate-button-title">Evaluate</span>
          <span className="evaluate-button-subtitle">Review for job fit and keywords</span>
        </button>
      </div>
      <select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        className="optimize-dropdown"
        disabled={!isOptimizeEnabled}
        hover-tooltip={DisabledTitle}
      >
        <option value="option1">Standard Optimizer v1.1</option>
        <option value="option2">Experimental v0.1</option>
      </select>
      <div className="slider-container" hover-tooltip={DisabledTitle}>
        <label>Tone:</label>
        <input type="range" min="1" max="5" defaultValue="3" disabled/>
      </div>
      <div className="slider-container" hover-tooltip={DisabledTitle}>
        <label>Specificity:</label>
        <input type="range" min="1" max="5" defaultValue="3" disabled/>
      </div>
      <button 
        onClick={handleRegenerate} 
        className="regenerate-button"
        hover-tooltip={DisabledTitle}
        disabled
      >
        Regenerate
      </button>
    </div>
  );
}

export default Optimize;