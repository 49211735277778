import React from 'react';
import { Link } from 'react-router-dom';
import '../style/page/Privacy.css';

function Privacy() {
  return (
    <div className="privacy-page">
      <Link to="/" className="back-to-home">Back to Home</Link>
      <h1>Privacy Policy</h1>
      <p>Last updated: September 26, 2024</p>
  
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to Resume Workshop. We are committed to protecting your personal information and your right to privacy. 
          This Privacy Policy explains how we collect, use, and protect your data when you use our service.</p>
      </section>
      
      <section>
        <h2>2. Information We Collect</h2>
        <p>We collect personal information that you provide to us, such as name, email address, and your submitted resume details. 
          We also collect usage data and may use cookies for various purposes.</p>
      </section>
      
      <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use your information to provide and improve our service, including:</p>
        <ul>
          <li>Facilitating resume creation and editing</li>
          <li>Providing customer support</li>
          <li>Analyzing usage to improve our service</li>
          <li>Sending you updates and promotional offers (with opt-out option)</li>
        </ul>
      </section>
      
      <section>
        <h2>4. Data Protection Rights</h2>
        <p>Depending on your location, you may have certain data protection rights under GDPR, CalOPPA, or CCPA. These may include the right to access, correct, or delete your personal information.</p>
        <p>If you are a resident of the European Union (EU) and European Economic Area (EEA), 
          you have certain data protection rights, covered by the General Data Protection Regulation (GDPR).
           Please visit <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj" target="_blank" rel="noopener noreferrer">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a> for further details.</p>
        <p>If you are a resident of California in the United States of America (USA), you are protected under the California Online Privacy Protection Act (CalOPPA) 
          and the California Consumer Privacy Act (CCPA). You have the right to request information about how we disclose your personal information 
          to third parties for their direct marketing purposes. You may also request that we do not share your information with third parties for their direct marketing purposes. 
          See more at <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/" target="_blank" rel="noopener noreferrer">CalOPPA</a> and <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375" target="_blank" rel="noopener noreferrer">CCPA</a>.</p>     
      </section>
      
      <section>
        <h2>5. Third Party Services</h2>
        <p>We may use third party services to enhance our service. These services may collect information used to identify you. At the time of this update, we currently partner with:</p>
        <ul>
          <li>Amazon Web Services</li>
          <li>Google Analytics</li>
          <li>Stripe</li>  
        </ul>
        <p>Please refer to their privacy policies for more details on how they handle your data.</p>
      </section>

      <section>
        <h2>6. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "last updated" date.</p>
      </section>
      
      <section>
        <h2>7. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at: support@resume-workshop.com</p>
      </section>
      
      <Link to="/" className="back-to-home">Back to Home</Link>
    </div>
  );
}

export default Privacy;