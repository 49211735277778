const awsConfig = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_mATBJiAvM",
  "aws_user_pools_web_client_id": "472dkumfjo990tptt904d6o5j4",
  "oauth": {
    "domain": "resume-workshop.auth.us-east-1.amazoncognito.com",
    "scope": [ "openid", "email", "profile", "aws.cognito.signin.user.admin"],
    "redirectSignIn": "https://resume-workshop.com/app",
    "redirectSignOut": "https://resume-workshop.com",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
}

export default awsConfig;