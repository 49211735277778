import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { tiers } from '../config/pricingTiers.js';
import { linkedInPosts } from '../config/linkedInPosts.js';
import { faqs } from '../config/faqs.js';
import logoImage from '../assets/resume-workshop-logo-blue.png'; 
import logoImageTitle from '../assets/resume-workshop-logo-black.png'; 
import demoVideo from '../assets/resume_workshop_demo.mp4'; 
import appScreenshot from '../assets/app-screenshot.png';
import featureImage1 from '../assets/feature-image-1.png';
import featureImage2 from '../assets/feature-image-2.png';
import featureImage3 from '../assets/feature-image-3.png';
import '../style/page/Home.css';

function Home() {
  const navigate = useNavigate();
  const pricingRef = useRef(null);
  //const blogRef = useRef(null);
  const faqRef = useRef(null);
  const helpRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };

  const handleAppClick = (e) => {
    e.preventDefault();
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (isLoggedIn) {
      navigate('/app');
    } else {
      navigate('/app', { state: { showSubscribe: true } });
    }
  };
  
  const [showScrollTop, setShowScrollTop] = useState(false);
  const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' });};
  const scrollToSection = (ref) => { ref.current.scrollIntoView({ behavior: 'smooth' }); };

  const scrollToNextSection = () => {
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const tooltips = [
    { id: 'input', top: '5%', left: '40%', text: 'Upload your resume and the job description.' },
    { id: 'controls', top: '20%', left: '25%', text: 'Use the control panel to submit your resume and job description to our engine.' },
    { id: 'evaluation', top: '35%', left: '40%', text: 'Receive an analysis of your resume with suggestions for improvement and keywords extracted from the job post.' },
    { id: 'optimization', top: '70%', left: '40%', text: 'Use our inline editor to make your own edits.' },
  ];
  
  // { id: 'settings', top: '90%', left: '5%', text: 'Manage your account and settings.'},
  //  { id: 'feedback', top: '90%', left: '90%', text: 'Report bugs and provide feedback.'}
  
  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScrollTop && window.scrollY > 200) {
        setShowScrollTop(true);
      } else if (showScrollTop && window.scrollY <= 200) {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScrollTop]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target) && !event.target.classList.contains('mobile-menu-icon')) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  
  /*
  const images = [
    "/path-to-image1.jpg",
    "/path-to-image2.jpg",
    "/path-to-image3.jpg",
  ];

  
  const [currentImage, setCurrentImage] = useState(0);
  const nextImage = () => { setCurrentImage((prev) => (prev + 1) % images.length); };
  const prevImage = () => { setCurrentImage((prev) => (prev - 1 + images.length) % images.length); };

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);
  */

  const handleLoginClick = (e) => {
    e.preventDefault();
    navigate('/app', { state: { showSubscribe: true, activeTab: 'login' } });
  };


  return (
    <div className="home">
      <nav className="menu-bar">
        <Link to="/app" onClick={handleAppClick} className="logo-container">
          <img src={logoImage} alt="Resume Workshop Logo" className="logo" />
          <span className="app-name">Resume Workshop</span>
        </Link>
        <div className="nav-links">
          <Link to="/app" onClick={handleAppClick}>Workshop</Link>
          <a href="#pricing" onClick={(e) => { e.preventDefault(); scrollToSection(pricingRef); }}>Pricing</a>
          {/*<a href="#blog" onClick={(e) => { e.preventDefault(); scrollToSection(blogRef); }}>Blog</a>*/} 
          <a href="#faq" onClick={(e) => { e.preventDefault(); scrollToSection(faqRef); }}>FAQ</a>
          <a href="#help" onClick={(e) => { e.preventDefault(); scrollToSection(helpRef); }}>Help</a>
        </div>
        <div className="login-container">
          <Link to="/app" onClick={handleLoginClick} className="login-button">Log In</Link>
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          ☰
        </div>
        <div className={`mobile-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <div className="mobile-menu-nav-links">
            <Link to="/app" onClick={() => { handleAppClick(); toggleMobileMenu(); }}>Workshop</Link>
            <a href="#pricing" onClick={(e) => { e.preventDefault(); scrollToSection(pricingRef); toggleMobileMenu(); }}>Pricing</a>
            {/*<a href="#blog" onClick={(e) => { e.preventDefault(); scrollToSection(blogRef); toggleMobileMenu(); }}>Blog</a>*/} 
            <a href="#faq" onClick={(e) => { e.preventDefault(); scrollToSection(faqRef); toggleMobileMenu(); }}>FAQ</a>
            <a href="#help" onClick={(e) => { e.preventDefault(); scrollToSection(helpRef); toggleMobileMenu(); }}>Help</a>
          </div>
          <div className="mobile-menu-login-container">
            <Link to="/app" onClick={(e) => { handleLoginClick(e); toggleMobileMenu(); }} className="mobile-menu-login-button">Log In</Link>
          </div>
        </div>
      </nav>
      
      <main className="home-content">
        <div className="title-container">
          <Link to="/app" onClick={handleAppClick} className="logo-link">
            <img src={logoImageTitle} alt="Resume Workshop Logo" className="title-logo" />
          </Link>
          <Link to="/app" onClick={handleAppClick} className="app-title-link">
           <h1 className="app-title">Resume Workshop</h1>
          </Link>
          </div>
        <Link to="/app" onClick={handleAppClick} className="app-subtitle">
          <h2 className="app-subtitle"><strong>Optimize</strong> your resume.</h2>
          <h2 className="app-subtitle">Get the <strong>job</strong> you deserve.</h2>
        </Link>
        <Link to="/app" onClick={handleAppClick} className="launch-button">Get Started</Link>
        <div className="video-container">
          <Link to="/app" onClick={handleAppClick}>
          <video autoPlay loop muted playsInline className="demo-video">
            <source src={demoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </Link>
        </div>
        <div className="scroll-indicator" onClick={scrollToNextSection}>
          <FaChevronDown className="chevron-down" />
        </div>
      </main>
      
      {showScrollTop && (
        <button onClick={scrollToTop} className={`scroll-to-top ${window.scrollY <= 200 ? 'inactive' : ''}`}>
          <FaChevronUp />
        </button>
      )}

      <section id="features" className="features">
        <div className="feature-grid">
          <div className="feature-item">
            <div className="feature-text">
              <Link to="/app" onClick={handleAppClick} className="feature-text-link">
                <h1>It's harder than ever to get your resume noticed</h1>
              </Link>
              <h2>Your unique skills and experience deserve <strong>recognition</strong>, but standing out feels like an uphill battle.</h2>    
              <h2>In the flood of applicants for every job posting, it's easy to get <strong>overlooked</strong>.</h2>
            </div>
            <div className="feature-image-container">
              <Link to="/app" onClick={handleAppClick} className="feature-image-link">
                <img src={featureImage1} alt="Feature 1" className="feature-image" />
              </Link>
              <a href="https://thesocialshepherd.com/blog/linkedin-statistics" className="feature-image-source" target="_blank" rel="noopener noreferrer">
                Source: The Social Shepherd
              </a>
            </div>
          </div>
          <div className="feature-item">
            <div className="feature-text">
              <Link to="/app" onClick={handleAppClick} className="feature-text-link">
                <h1>Competition is fierce</h1>
              </Link>
              <h2>The job market is crowded and only getting worse.</h2>
              <h2>Every opportunity feels like a <strong> long shot</strong>.</h2>
            </div>
            <div className="feature-image-container">
              <Link to="/app" onClick={handleAppClick} className="feature-image-link">
                <img src={featureImage2} alt="Feature 2" className="feature-image" />
              </Link>
              <a href="https://www.zippia.com/advice/linkedin-statistics/" className="feature-image-source" target="_blank" rel="noopener noreferrer">
                Source: Zippia
              </a>
            </div>
          </div>
          <div className="feature-item">
            <div className="feature-text">
              <Link to="/app" onClick={handleAppClick} className="feature-text-link">
                <h1>Application screening is getting tougher</h1>
              </Link>
              <h2>More likely than not, your resume is being <strong>rejected</strong> before a real person ever gets to look at it.</h2>
            </div>
            <div className="feature-image-container">
              <Link to="/app" onClick={handleAppClick} className="feature-image-link">
                <img src={featureImage3} alt="Feature 3" className="feature-image" />
              </Link>
              <a href="https://www.selectsoftwarereviews.com/blog/applicant-tracking-system-statistics" className="feature-image-source" target="_blank" rel="noopener noreferrer">
                Source: SelectSoftware Reviews
              </a>
            </div>
          </div>
        </div>
        <Link to="/app" onClick={handleAppClick} className="launch-button">Launch Workshop</Link>
      </section>

      <section className="how-it-works">
        <Link to="/app" onClick={handleAppClick} className="how-it-works-link">
          <h1>Give Yourself The Best Chance</h1>
        </Link>
        <div className="how-it-works-subtitle">
          <h2><strong>Resume Workshop</strong> optimizes your resume with the <strong>keywords</strong> and <strong>phrases</strong> that best fit the job you're applying for.</h2>
          <h3>Our most advanced optimizer is being trained on <strong>14,000+</strong> publicly available job postings. It uses this data to find the <strong>best possible way</strong> to showcase your skills and experience.</h3>
        </div>
        <div className="app-screenshot-container">
          <img src={appScreenshot} alt="Resume Workshop App Screenshot" className="app-screenshot" />
          {tooltips.map((tooltip) => (
            <div
              key={tooltip.id}
              className={`tooltip-trigger ${activeTooltip === tooltip.id ? 'active' : ''}`}
              style={{ top: tooltip.top, left: tooltip.left }}
              onMouseEnter={() => setActiveTooltip(tooltip.id)}
              onMouseLeave={() => setActiveTooltip(null)}
            >
              <div className="tooltip-content">
                {tooltip.text}
              </div>
            </div>
          ))}
        </div>
        <h2>We help <strong>job seekers</strong> apply to jobs with the <strong>best</strong> version of themselves.</h2>
        <Link to="/app" onClick={handleAppClick} className="launch-button">Get Started</Link>
      </section>

      <section ref={pricingRef} id="pricing" className="pricing">
        <h2>Choose Your Plan</h2>
        <div className="pricing-tiers">
          {tiers.map((tier) => (
            <div key={tier.name} className="pricing-tier">
              {tier.label && <span className="tier-label">{tier.label}</span>}
              <div className="tier-content">
                <h3>{tier.name}</h3>
                <div className="price">
                  {tier.price}<span className="period">/{tier.period}</span>
                </div>
                <p className="total-price">{tier.totalPrice}</p>
                <div className="tier-features">
                  {tier.features.map((feature, index) => (
                    <p key={index}>{feature}</p>
                  ))}
                </div>
              </div>
              <a href={tier.link} className="subscribe-button">
                Subscribe
              </a>
            </div>
          ))}
        </div>
      </section>

      <section className="top-voices">
        <h1>What People Are Saying</h1>
        <h2>Top Voices on the Job Market & Resume Optimizing</h2>
        <div className="linkedin-posts-container">
          {linkedInPosts.map((post, index) => (
            <div key={index} className="linkedin-post">
              <div className="linkedin-post-header">
                <img 
                  src={post.profilePictureUrl} 
                  alt={`${post.author} profile`} 
                  className="linkedin-profile-picture"
                />
                <div className="linkedin-post-author">
                  <p className="author">{post.author}</p>
                  <p className="position">{post.position}</p>
                </div>
              </div>
                <p className="title">{post.title}</p>
                <div className="content">
                  {post.content.split('\n').map((line, i) => (
                    <p key={i}>{line}</p>
                  ))}
                </div>
              <a href={post.link} target="_blank" rel="noopener noreferrer" className="linkedin-link">
                View Article
              </a>
            </div>
          ))}
        </div>
        <Link to="/app" onClick={handleAppClick} className="launch-button">Launch Workshop</Link>
      </section>

      {/*<section ref={blogRef} id="blog" className="blog-preview">
        <h2>Follow us on LinkedIn</h2>
        <div className="blog-grid">
          <div className="blog-post">
            <h3>Top 10 Resume Tips for 2023</h3>
            <p>Learn the latest trends in resume writing...</p>
            <Link to="/blog/top-10-resume-tips">Read More</Link>
          </div>
          <div className="blog-post">
            <h3>How to Tailor Your Resume for Tech Jobs</h3>
            <p>Specific advice for landing your next tech role...</p>
            <Link to="/blog/tech-resume-tips">Read More</Link>
          </div>
        
        </div>
        <h2>Ready to optimize your resume?</h2>
        <Link to="/app" onClick={handleAppClick} className="launch-button">Get Started</Link>
      </section>*/}

      <section ref={faqRef} id="faq" className="faq-section">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-list">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <p className="faq-question"><strong>{faq.question}</strong></p>
                <div className="faq-answer">
                  {faq.answer.split('\n').map((line, i) => (
                    <p key={i}>{line}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Link to="/app" onClick={handleAppClick} className="launch-button">Try It Now</Link>
        </section>

      <section ref={helpRef} id="help" className="help-section">
        <h2>Need Help?</h2>
        <div className="help-content">
          {/* <h3>Frequently Asked Questions</h3>
          <ul>
            <li><a href="#">How do I get started?</a></li>
            <li><a href="#">What file formats are supported?</a></li>
            <li><a href="#">Can I use the app on mobile devices?</a></li>
            {/* Add more FAQs as needed
          </ul> 
          <h3>Contact Support</h3>*/}
          <p>If you can't find the answer you're looking for, please <a href="mailto:support@resume-workshop.com">contact our support team</a>.</p>
        </div>
      </section>

      <footer className="site-footer">
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
        <p className="copyright">Resume Workshop &copy; 2024-Present. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;