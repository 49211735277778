import React from 'react';
import { Link } from 'react-router-dom';
import '../style/page/Terms.css';

function Terms() {
  return (
    <div className="terms-page">
      <Link to="/" className="back-to-home">Back to Home</Link>
      <h1>Terms of Service</h1>
      <p>Last updated: September 26, 2024</p>
      
      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using Resume Workshop's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services.</p>
      </section>
      
      <section>
        <h2>2. Use of Service</h2>
        <p>You may use Resume Workshop to create, edit, and manage your resume. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>
      </section>
      
      <section>
        <h2>3. Privacy Policy</h2>
        <p>Your use of Resume Workshop is also governed by our Privacy Policy. Please review our <Link to="/privacy">Privacy Policy</Link> to understand our practices regarding the collection, use, and disclosure of your personal information.</p>
      </section>
      
      <section>
        <h2>4. Intellectual Property</h2>
        <p>The content, features, and functionality of Resume Workshop are owned by us and are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of our content without explicit permission.</p>
      </section>
      
      <section>
        <h2>5. User Content</h2>
        <p>You retain all rights to the content you submit to Resume Workshop. By submitting content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and display your content solely for the purpose of providing our services to you.</p>
      </section>
      
      <section>
        <h2>6. Payments and Refunds</h2>
        <p>If you choose to purchase premium features, payment will be charged to your account at confirmation of purchase. Subscriptions automatically renew unless canceled at least 24 hours before the end of the current period.</p>
        <p><strong>Refund Policy:</strong> Due to the nature of our digital services, refunds are only available on a case-by-case basis. If you'd like to request a refund please contact us at support@resume-workshop.com for assistance.</p>
      </section>
      
      <section>
        <h2>7. Termination</h2>
        <p>We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms of Service or is harmful to other users, us, or third parties, or for any other reason.</p>
      </section>
      
      <section>
        <h2>8. Limitation of Liability</h2>
        <p>Resume Workshop is provided on an "as is" and "as available" basis. We do not guarantee that our services will be uninterrupted, timely, secure, or error-free. To the fullest extent permitted by law, we disclaim all warranties, express or implied, in connection with the service.</p>
      </section>
      
      <section>
        <h2>9. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms of Service at any time. We will notify users of any significant changes by posting a notice on our website. Your continued use of Resume Workshop after changes to the Terms of Service constitutes your acceptance of the new terms.</p>
      </section>
      
      <section>
        <h2>10. Contact Information</h2>
        <p>If you have any questions about these Terms of Service, please contact us at: support@resume-workshop.com</p>
      </section>
      
      <Link to="/" className="back-to-home">Back to Home</Link>
    </div>
  );
}

export default Terms;
