import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { tiers } from '../../../config/pricingTiers.js';
import { signIn, signInWithRedirect, resetPassword, confirmResetPassword } from '@aws-amplify/auth';
import '../../../style/app/component/util/Subscribe.css';


const Subscribe = ({ onClose, initialActiveTab = 'subscribe', checkAuthStatus }) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetPasswordStep, setResetPasswordStep] = useState('request'); // 'request' or 'confirm'
  const [error, setError] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const navigate = useNavigate();
  const emailInputRef = useRef(null);

  const handleSocialSignIn = async (provider) => {
    setError('');
    try {
      await signInWithRedirect({ provider: provider });
      // The user will be redirected to the social provider's login page
    } catch (error) {
      console.error(`Error signing in with ${provider}:`, error);
      setError(`Failed to sign in with ${provider}. Please try again. Error: ${error.message}`);
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    setIsLoggingIn(true);
    setError('');

    try {
      await signIn({ username: email, password });
      console.log('Sign-in successful');
      await checkAuthStatus();
      
      onClose();
      navigate('/app');

    } catch (error) {
      console.error('Error signing in:', error);
      setError(error.message || 'Failed to sign in. Please try again.');
    
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const email = emailInputRef.current.value;
    if (!email) {
      setError('Please enter your email address first.');
      return;
    }

    setError('');
    setIsResettingPassword(true);
    setResetEmail(email);

    try {
      await resetPassword({ username: email });
      setResetPasswordStep('confirm');
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(error.message || 'Failed to reset password. Please try again.');
    } finally {
      setIsResettingPassword(false);
    }
  };

  const handleConfirmResetPassword = async (e) => {
    e.preventDefault();
    const code = e.target.code.value;
    const newPassword = e.target.newPassword.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setError('');
    setIsResettingPassword(true);

    try {
      await confirmResetPassword({ username: resetEmail, confirmationCode: code, newPassword });
      setError('Password has been successfully reset. You can now log in with your new password.');
      setResetPasswordStep('request');
      setIsResettingPassword(false);
    } catch (error) {
      console.error('Error confirming password reset:', error);
      setError(error.message || 'Failed to confirm password reset. Please try again.');
      setIsResettingPassword(false);
    }
  };


  const renderResetPasswordForm = () => (
    <form className="password-reset-form" onSubmit={handleConfirmResetPassword}>
      <input 
        type="email" 
        value={resetEmail} 
        disabled 
        placeholder="Email"
      />
      <input type="text" name="code" placeholder="Verification Code" required />
      <input type="password" name="newPassword" placeholder="New Password" required />
      <input type="password" name="confirmPassword" placeholder="Confirm New Password" required />
      <button type="submit" className="password-reset-button" disabled={isResettingPassword}>
        {isResettingPassword ? 'Resetting Password...' : 'Reset Password'}
      </button>
      <div className="auth-switch">
        <Link to="#" onClick={() => setResetPasswordStep('request')}>Back to Login</Link>
      </div>
    </form>
  );

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <div className="auth-header">
          <div className="auth-tabs">
            <button 
              className={`auth-tab ${activeTab === 'subscribe' ? 'active' : ''}`}
              onClick={() => setActiveTab('subscribe')}
            >
              Subscribe
            </button>
            <button 
              className={`auth-tab ${activeTab === 'login' ? 'active' : ''}`}
              onClick={() => setActiveTab('login')}
            >
              Log In
            </button>
          </div>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        
        {activeTab === 'subscribe' && (
          <div className="subscribe-content">
            <h2 className="auth-title">Choose Your Subscription</h2>
            <div className="subscription-tiers">
              {tiers.map((tier) => (
                <div key={tier.name} className="subscription-tier">
                  {tier.label && <span className="tier-label">{tier.label}</span>}
                  <div className="tier-content">
                    <h3>{tier.name}</h3>
                    <div className="price">
                      {tier.price}<span className="period">/{tier.period}</span>
                    </div>
                    <p className="total-price">{tier.totalPrice}</p>
                    <div className="tier-features">
                      {tier.features.map((feature, index) => (
                        <p key={index}>{feature}</p>
                      ))}
                    </div>
                  </div>
                  <a href={tier.link} className="subscribe-button">
                    Subscribe
                  </a>
                </div>
              ))}
            </div>
            <div className="auth-switch">
              Already have an account? <Link to="#" onClick={() => setActiveTab('login')}>Log in here</Link>.
            </div>
          </div>
        )}
        
        {activeTab === 'login' && (
          <div className="login-content">
            <h2 className="auth-title">Log In</h2>
            {error && <div className="error-message">{error}</div>}
            {resetPasswordStep === 'request' ? (
              <>
                <div className="social-logins">
                  <button className="social-login-button google" onClick={() => handleSocialSignIn('Google')}>
                    Log in with Google
                  </button>
                  {/*<button className="social-login-button linkedin" onClick={() => handleSocialSignIn('LinkedIn')}>
                    Log in with LinkedIn
                  </button>*/}
                </div>

                <div className="separator">
                  <span>or</span>
                </div>
                  {<form className="email-login" onSubmit={handleEmailSignIn}>
                    <input 
                      type="email" 
                      name="email" 
                      ref={emailInputRef}
                      placeholder="Enter your email" 
                      required 
                    />
                    <input type="password" name="password" placeholder="Enter your password" required />
                    <button type="submit" className="email-login-button" disabled={isLoggingIn}>
                      {isLoggingIn ? 'Logging in...' : 'Log in with Email'}
                    </button>
                    {isLoggingIn && <div className="logging-in-message">Verifying log-in credentials...</div>}
                    <div className="forgot-password">
                      <Link 
                        to="#" 
                        onClick={handleForgotPassword}
                        className={isResettingPassword ? 'disabled' : ''}
                      >
                        {isResettingPassword ? 'Sending reset instructions...' : 'Forgot Password?'}
                      </Link>
                    </div>
                  </form>}
              </>
            ) : (
              renderResetPasswordForm()
            )}
            {resetPasswordStep === 'request' && (
              <div className="auth-switch">
                Don't have an account? <Link to="#" onClick={() => setActiveTab('subscribe')}>Subscribe here</Link>.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Subscribe;