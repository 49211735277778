export const tiers = [
  {
    name: 'Monthly',
    price: '$20',
    period: 'month',
    features: [
      'Suitable for passive job seekers', 
      'Limited expected job search and one time resume updates.',
      'Tailor your resume for each job posting.', 
      'Cancel anytime.'
    ],
    link: 'https://buy.stripe.com/4gwg2e2nbfHD97a008'
  },
  {
    name: 'Annual',
    price: '$90',
    period: 'year',
    features: [
      'Best for active job seekers.',
      'Access to our advanced and experimental optimizers.',
      'Cancel anytime.'
    ],
    label: 'Most Popular',
    link: 'https://buy.stripe.com/9AQ3fs0f39jf4QU8wD'
  },
  {
    name: 'Lifetime',
    price: '$150',
    period: '∞',
    features: [
      'One payment. Unlimited access.',
      'Customize your resume whenever you\'re interested in exploring the job market.',
      'Access to our advanced and experimental optimizers.',
    ],
    label: 'Best Value',
    link: 'https://buy.stripe.com/28o6rE2nb2UR1EI8wC'
  }
];