export const faqs = [
    {
      question: "What makes Resume Workshop different from other resume platforms?",
      answer: "Current alternatives, particularly the free-to-use platforms, are centered around resume building for first-time job seekers using template libraries. \n\nIn contrast, Resume Workshop's sole focus is to specialize in optimizing your resume relative to job postings. \n\nOur most advanced optimizer is being trained on 14,000+ publicly available job postings, and will continue to be updated as new data becomes available. This allows us to provide a fully customized and tailored result for job seekers, using the same language and context as hiring managers use in their job postings."
    },
    {
      question: "Why don't you offer a free trial?",
      answer: "The optimizers are more resource-intensive to run than most online software services. In development, we couldn't find a suitable free tier offering that we could support that also provided the user with an enjoyable and helpful Workshop experience.\n\nWe did not want to go the route of offering a free trial to tease users into registering only to keep the truly useful features behind our subscriptions.\n\nIf cost is an issue for you, please follow our social media accounts. We occasionally post discounts and promotions for new and returning users."
    },
    {
      question: "What is your refund policy?",
      answer: "At this time, refunds are only available for our standard monthly subscription tier. If you are considering canceling your monthly service and would like to request a refund, please contact us using the help link below. A support member will be in touch within 24 to 48 hours."
    },
    {
      question: "How long is my data stored? Can you retrieve my data if I cancel?",
      answer: "Data related to your personal resumes and job applications are stored for 30 days from the time it is submitted to our system. We are not able to retrieve your data after this time period regardless of your subscription status."
    },
    {
      question: "Is there a mobile version of Resume Workshop?",
      answer: "The Workshop app was designed with desktop use in mind. We have no plans to develop a mobile version at this time."
    }
  ];