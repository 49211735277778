export const linkedInPosts = [
  {
    profilePictureUrl: "https://media.licdn.com/dms/image/v2/C4D03AQEayrMUbxD_zA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1589416333227?e=1733356800&v=beta&t=fNvoYdEROI8Q5DkUrFKxRY80P7PCqAyGOhnbEtJM_94",
    author: "Andrew Seaman",
    position: "Editor-At-Large for Jobs & Career Development at LinkedIn News",
    title: "You Should Be Taking Advantage of AI During Your Job Search",
    content: "Familiarizing yourself with artificial intelligence (AI) technology is no longer a luxury — it’s a necessity. As AI becomes a standard tool across industries, job seekers who understand and use AI will stand out from the competition...",
    link: "https://www.linkedin.com/pulse/you-should-taking-advantage-ai-during-your-job-search-andrew-seaman-h650f/",
  },
  {
    profilePictureUrl: "https://media.licdn.com/dms/image/v2/D5603AQENTquHSjMpeA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1695771605933?e=1733356800&v=beta&t=-l7F6TpeRIuNarX6_i8L_B1rBWt5YLTSCcKX-oDHLzQ",
    author: "Jenny Foss",
    position: "Career Development Consultant | Professional Resume Writer | Published Author | I help people navigate job search, advance their careers, and make career pivots",
    title: "5 Common Resume Mistakes & How to Fix Them",
    content: "Crafting a resume you feel great about can feel nearly impossible, I know. My entire industry exists because resume writing is so challenging, for nearly all of us. But it’s absolutely worth your best effort if you’re looking to land an interview and, ultimately, a fantastic new job...",
    link: "https://www.linkedin.com/pulse/5-common-resume-mistakes-how-fix-them-jenny-foss-ubjic/?trackingId=TZ3xVKoRS2GddtwzMCRsqw%3D%3D"
  },
];