import React from 'react';
import '../../../style/app/component/util/Settings.css';

const Settings = () => {
  return (
    <div className="settings-popup">
      <h3>Settings</h3>
      <ul>
        <li>Theme: <select disabled><option>Light</option><option>Dark</option></select></li>
        <li>Language: <select disabled><option>English</option><option>Spanish</option></select></li>
        <li>Notifications: <input type="checkbox" disabled /></li>
        <li>Tooltips: <input type="checkbox" disabled /></li>
      </ul>
    </div>
  );
};

export default Settings;