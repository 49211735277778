import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { getCurrentUser, signOut, fetchAuthSession, updateUserAttributes, fetchUserAttributes } from '@aws-amplify/auth';
import { FiUser, FiSettings, FiHelpCircle, FiLogOut, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import logoImage from '../assets/resume-workshop-logo-blue.png'; 
import Subscribe from './component/util/Subscribe';
import Settings from './component/util/Settings';
import Optimize from './component/interface/Optimize';
import ReactMarkdown from 'react-markdown';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../style/app/App.css';


function App() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const initialShowSubscribe = location.state?.showSubscribe || false;
  const initialActiveTab = location.state?.activeTab || 'subscribe';
  const [showSubscribe, setShowSubscribe] = useState(initialShowSubscribe);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const [isLeftColumnCollapsed, setIsLeftColumnCollapsed] = useState(true);  
  const [showSettings, setShowSettings] = useState(false);
  
  // Input constants
  const [userToken, setUserToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [resumeText, setResumeText] = useState('');
  const [resumeFile, setResumeFile] = useState(null);
  const [jobPostText, setJobPostText] = useState('');
  const [jobPostLink, setJobPostLink] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const DisabledTitle = "Feature not yet enabled.";

  // Output constants
  const [jobTitle, setJobTitle] = useState('');
  const [requestResult, setRequestResult] = useState('');
  const [evaluationResult, setEvaluationResult] = useState('');
  const [optimizedResult, setOptimizedResult] = useState('');
  const [isEvalStreamComplete, setIsEvalStreamComplete] = useState(false);
  

  const checkAuthStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const user = await getCurrentUser();
      const pendingSessionId = sessionStorage.getItem('pendingSessionId');
      
      if (user) {
        if (pendingSessionId) {
          await updateUserAttributes({ 
            userAttributes: { 
              "custom:session_id": pendingSessionId,
              "custom:registered": "true" 
            }
          });
          sessionStorage.removeItem('pendingSessionId');
        } else {
          // Check for registered users.
          const userAttributes = await fetchUserAttributes();
          const registered = userAttributes['custom:registered'];
          
          if (!registered || registered !== 'true') {
            console.log('User is not registered. Redirecting to create account.');
            await signOut();
            navigate('/create-account');
          }
        }
  
        const session = await fetchAuthSession();
        const userSub = session.userSub;
        const token = session.tokens.idToken;

        setIsLoggedIn(true);
        setShowSubscribe(false);
        setUserToken(token);
        setUserId(userSub);
  
      } else if (location.pathname === '/app') {
        setIsLoggedIn(false);
        setUserToken(null);
        setUserId(null);
        setShowSubscribe(true);
      }
    } catch (error) {
      // Check if the error is due to user not being authenticated
      if (error.name === 'UserUnAuthenticatedException') {
        console.log('User is not authenticated');
        setIsLoggedIn(false);
        setUserToken(null);
        setUserId(null);
        if (location.pathname === '/app') {
          setShowSubscribe(true);
        }
      } else {
        // Log other errors
        console.error('Error checking auth status:', error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [location.pathname, navigate, setIsLoading, setIsLoggedIn, setShowSubscribe, setUserToken, setUserId]);


  const handleCloseSubscribe = useCallback(() => {
    if (isLoggedIn) {
      setShowSubscribe(false);
    } else {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  const handleBillingClick = () => {
    window.open('https://billing.stripe.com/p/login/5kA8xqaQB0U33cs000', '_blank');
  };

  const toggleSettings = (e) => {
    e.preventDefault();
    setShowSettings(!showSettings);
  };
  
  const handleHelpClick = () => {
    window.location.href = 'mailto:support@resume-workshop.com';
  };

  const handleLogout = async () => {
    try {
      await signOut();
      setIsLoggedIn(false);
      setUserToken(null);
      setUserId(null);
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const handleResumeTextChange = (e) => {
    setResumeText(e.target.value);
  };

  const handleResumeFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setResumeFile(file);
      setResumeText('');
    }
  };

  const handleRemoveResumeFile = () => {
    setResumeFile(null);
    const fileInput = document.getElementById('resume-file-input');
    if (fileInput) fileInput.value = '';
  };

  const handleJobPostTextChange = (e) => {
    setJobPostText(e.target.value);
  };

  const handleJobPostLinkChange = (e) => {
    const url = e.target.value;
    setJobPostLink(url);
    if (isValidUrl(url)) {
      setJobPostText('');
    }
  };

  const handleRemoveJobPostLink = () => {
    setJobPostLink('');
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const quillRef = useRef(null);
  
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      [{ 'align': [] }],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'link', 'align'
  ];

  const handleEditorChange = (content) => {
    setOptimizedResult(content);
  };

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);
  
  useEffect(() => {
    if (requestResult && requestResult.evaluation) {
      console.log("requestResult: ", requestResult);
      setEvaluationResult('');
      setIsEvalStreamComplete(false);
      let index = 0;
      const intervalId = setInterval(() => {
        setEvaluationResult(requestResult.evaluation.slice(0, index + 1));
        index++;
        if (index >= requestResult.evaluation.length) {
          clearInterval(intervalId);
          setIsEvalStreamComplete(true);
          
          // Set the optimized result immediately after evaluation is complete
          if (requestResult.optimization) {
            const formattedOptimization = requestResult.optimization
              .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
              .replace(/\*(.*?)\*/g, '<em>$1</em>')
              .replace(/\n/g, '<br>');
            setOptimizedResult(formattedOptimization);
          }
        }
      }, 5);
  
      return () => clearInterval(intervalId);
    }
  }, [requestResult]);
  
  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.format('align', 'left');
    }
  }, [optimizedResult]);

  return (
    <div className="App">
            {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className={`left-column ${isLeftColumnCollapsed ? 'collapsed' : ''}`}>
        <div className="top-section">
          <Link to="/" className="logo-and-name">
            <img src={logoImage} alt="logo" />
            {!isLeftColumnCollapsed && <h1 className="app-title">Resume Workshop</h1>}
          </Link>
          <button 
            className="collapse-button"
            onClick={() => setIsLeftColumnCollapsed(!isLeftColumnCollapsed)}
          >
            {isLeftColumnCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
          </button>
        </div>
        {!isLeftColumnCollapsed && (
          <div className="request-history">
            <h3>Welcome!</h3>
          </div>
        )}
        <div className="settings">
          <div className="settings-container">
            <button
              className="nav-button"
              onClick={handleBillingClick}
              hover-tooltip={isLeftColumnCollapsed ? "Account" : undefined}>
                <FiUser /> {!isLeftColumnCollapsed && "Account"}
            </button>
            <button
              className="nav-button"
              onClick={toggleSettings}
              hover-tooltip={isLeftColumnCollapsed ? "Settings" : undefined}>
                <FiSettings /> {!isLeftColumnCollapsed && "Settings"}
            </button>
            {showSettings && <Settings />}
          
            <button
              className="nav-button"
              onClick={handleHelpClick}
              hover-tooltip={isLeftColumnCollapsed ? "Help" : undefined}>
                <FiHelpCircle /> {!isLeftColumnCollapsed && "Help"}
            </button>
          
            <button 
              className="nav-button"
              onClick={handleLogout} 
              hover-tooltip={isLeftColumnCollapsed ? "Logout" : undefined}>
                <FiLogOut /> {!isLeftColumnCollapsed && "Logout"}
            </button>
          </div>
        </div>
      </div>
      
      <div className="main-column">
        <div className="input-sections">
          <div className="input-resume">
            <h3>Resume Input (PDF or Text)</h3>
            <div className="resume-input-container">
              <div className="file-input-wrapper">
                <input
                  id="resume-file-input"
                  type="file"
                  accept=".pdf"
                  onChange={handleResumeFileUpload}
                  /*disabled={resumeText.length >= 100} */
                  disabled
                  title={DisabledTitle}
                />
                {resumeFile && (
                  <button className="remove-file" onClick={handleRemoveResumeFile}>X</button>
                )}
              </div>
      
            </div>
            <div className="or-separator">-- or --</div>
            <textarea
              placeholder="Paste your resume text here..."
              value={resumeText}
              onChange={handleResumeTextChange}
              disabled={resumeFile !== null}
            />
          </div>
          <div className="input-job-post">
            <h3>Job Post Input (URL or Text)</h3>
            <div className="job-post-input-container">
              <input
                type="url"
                placeholder="Paste job post link here..."
                value={jobPostLink}
                onChange={handleJobPostLinkChange}
                /*disabled={jobPostText.length >= 100}*/
                disabled
                title={DisabledTitle}
              />
              {isValidUrl(jobPostLink) && (
                <button className="remove-link" onClick={handleRemoveJobPostLink}>X</button>
              )}
            </div>
            <div className="or-separator">-- or --</div>
            <textarea
              placeholder="Paste job post details here..."
              value={jobPostText}
              onChange={handleJobPostTextChange}
              disabled={isValidUrl(jobPostLink)}
            />
          </div>
        </div>
        
        <div className="control-panel">
          <Optimize 
            resumeText={resumeText}
            resumeFile={resumeFile}
            jobPostText={jobPostText}
            jobPostLink={jobPostLink}
            setRequestResult={setRequestResult}
            setIsProcessing={setIsProcessing}
            setJobTitle={setJobTitle}
            setEvaluationResult={setEvaluationResult}
            setOptimizedResult={setOptimizedResult}
            userToken={userToken}
            userId={userId}
          />
        </div>

        <div className="document-container">
        <div className="evaluation-container">
          <h2>Resume Evaluation</h2>
          {isProcessing ? (
            <div className="processing-request">
              <p>Running evaluation and optimization<span className="ellipsis"></span></p>
              <p>Our apologies for slow response time. We're working to increase capacity.</p>
            </div>
          ) : (
            <div className="evaluation-result-wrapper">
              <ReactMarkdown className={evaluationResult ? 'evaluation-result' : 'no-evaluation'}>
                {evaluationResult || 'No evaluation available.'}
              </ReactMarkdown>
            </div>
          )}
        </div>
          <div className="optimization-container">
            <h2>Optimized Resume</h2>
            <ReactQuill
              value={optimizedResult}
              onChange={handleEditorChange}
              modules={modules}
              formats={formats}
              theme="snow"
            />
          </div>
        </div>
      </div>

      <div className="floating-feedback">
        <a href="https://resume-workshop.canny.io/reports" target="_blank" rel="noopener noreferrer">
          <span>Reports & Feedback</span>
        </a>
        <div className="logo-container">
          <img src={logoImage} alt="App logo" className="feedback-logo" />
        </div>  
      </div>

      {showSubscribe && (
        <Subscribe 
          onClose={handleCloseSubscribe}
          initialActiveTab={initialActiveTab}
          checkAuthStatus={checkAuthStatus}
        />
      )}
    </div>
  );
}

export default App;