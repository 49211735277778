import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut, signUp, confirmSignUp, resendSignUpCode, signIn, signInWithRedirect  } from '@aws-amplify/auth';
import '../style/page/CreateAccount.css';

const CreateAccount = () => {  
  const location = useLocation();
  const navigate = useNavigate();
  const verifySubURL = process.env.REACT_APP_VERIFY_URL;
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [needsVerification, setNeedsVerification] = useState(false);
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);
  
  const verifySubscription = useCallback(async () => {
    if (sessionId) {
      try {
        const response = await fetch(verifySubURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId }),
        });
  
        const data = await response.json();
  
        if (data.verified) {
          setIsSubscribed(true);
          setEmail(data.email);
        } else {
          setError(data.message || 'Unable to verify your subscription. If this error persists please use the account creation link provided in your payment confirmation email.');
        }
      } catch (error) {
        console.error('Error verifying account:', error);
        setError('Unable to verify your subscription. If this error persists please use the account creation link provided in your payment confirmation email.');
      }
    } else {
      setError('Unable to verify your subscription. Please use the account creation link provided in your payment confirmation email.');
    }
    setIsLoading(false);
  }, [verifySubURL, sessionId, setIsSubscribed, setEmail, setError, setIsLoading]);

  const handleSocialSignIn = async (provider) => {
    setIsLoading(true);
    setError('');
    
    try {
      sessionStorage.setItem('pendingSessionId', sessionId);
      await signOut();
      await signInWithRedirect({ provider: provider });
      // The user will be redirected to the social provider's login page
    } catch (error) {
      console.error(`Error signing in with ${provider}:`, error);
      setError(`Failed to sign in with ${provider}. Please try again. Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setIsLoading(false);
      return;
    }

    try {
      // Sign up the user with Cognito
      sessionStorage.setItem('pendingSessionId', sessionId);
      await signOut();
      await signUp({
        username: email,
        password: password,
      });

      setPassword(password);
      setNeedsVerification(true);
    } catch (error) {
      console.error('Error creating account');
      if (error.name === 'InvalidPasswordException') {
        setError(error.message);
      } else {
        setError('Unable to create your account. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // Confirm the sign up with the verification code
      await confirmSignUp({
        username: email,
        confirmationCode: verificationCode,
      });
      // Sign-in and redirect to the main app
      await signIn({ username: email, password });
      navigate('/app');
    } catch (error) {
      console.error('Error completing verification.', error);
      setError('Unable to verify your account. Please check your verification code and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    setResendDisabled(true);
    setError('');
    try {
      await resendSignUpCode({ username: email });
      setError('A new verification code has been sent to your email.');
    } catch (error) {
      console.error('Error resending code:', error);
      setError('Failed to resend verification code. Please try again.');
    } finally {
      setTimeout(() => setResendDisabled(false), 30000);
    }
  };

  useEffect(() => {
    verifySubscription();
  }, [verifySubscription]);

  if (isLoading) {
    return <div>Verifying your subscription...</div>;
  }

  if (!isSubscribed) {
    return <div>{error || 'Unable to verify your subscription. Please use the account creation link provided in your payment confirmation email.'}</div>;
  }

  return (
    <div className="create-account-container">
      <h1>Create Your Account</h1>
      <p>Welcome! You're just one step away from accessing your Resume Workshop.</p>
      <p>Please create your account using your preferred login method below.</p>
      
      {error && <div className="error-message">{error}</div>}

      <div className="social-buttons">
        <button 
          className="social-button google" 
          onClick={() => handleSocialSignIn('Google')}
          disabled={isLoading}
        >
          Sign up with Google
        </button>
        {/*<button 
          className="social-button linkedin" 
          onClick={() => handleSocialSignIn('LinkedIn')}
          disabled={isLoading}
        >
          Sign up with LinkedIn
        </button>*/}
      </div>
      
      <div className="separator">
        <span>or</span>
      </div>

      {!needsVerification ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" id="password" required />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input type="password" id="confirmPassword" required />
            </div>
            <div className="password-requirements">
              <p>Password requirements:</p>
              <ul>
                <li>Minimum 8 characters long</li>
                <li>Contain 1 uppercase letter</li>
                <li>Contain 1 lowercase letter</li>
                <li>Contain 1 number</li>
                <li>Contain 1 special character</li>
              </ul>
            </div>
            <button type="submit" className="create-account-button" disabled={isLoading}>
              {isLoading ? 'Creating Account...' : 'Create Account'}
            </button>
          </form>
        </>
      ) : (
        <>
          <form onSubmit={handleVerification}>
            <div className="form-group">
              <label htmlFor="verificationCode">Check your email for verification code</label>
              <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="verify-account-button" disabled={isLoading}>
              {isLoading ? 'Verifying...' : 'Verify Account'}
            </button>
            <button 
              onClick={handleResendCode} 
              disabled={resendDisabled}
              className="resend-code-button"
            >
            Resend Verification Code
            </button>
          </form>
        </>
      )}
      <div className="trouble-link">Having trouble? <a href="mailto:support@getresume.workshop">Contact Us</a></div>
    </div>
    
  );
};

export default CreateAccount;